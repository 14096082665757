<section class="container" id="sdc-login-reset-page">

    <div class="card">
        <div class="card-body">
            <h2>Temporary Password Reset</h2>
            <p> Please update your temporary Secure Data Commons password immediately. A permanent password is required, it is imperative to avoid using a temporary password for extended periods.</p>
            <hr>
            <div class="form">
                <div class="input-group mb-3">
                    <input class="form-control" [type]="show_new_password ? 'text' :  'password'" placeholder="New Password" [(ngModel)]="new_password">
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="show_new_password = !show_new_password">
                        <i class="fa-solid " [ngClass]="show_new_password ? 'fa-eye' : 'fa-eye-slash' "></i>
                    </button>
                </div>
                <div class="input-group mb-3">
                    <input class="form-control" [type]="show_confirm_password ? 'text' :  'password'" placeholder="Confirm Password" [(ngModel)]="confirm_password">
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="show_confirm_password = !show_confirm_password">
                        <i class="fa-solid " [ngClass]="show_confirm_password ? 'fa-eye' : 'fa-eye-slash' "></i>
                    </button>
                </div>

                <button type="submit" class="btn btn-primary" [disabled]="new_password == undefined || confirm_password == undefined || is_loading == true" (click)="submit_reset_password()">

                    <ng-container *ngIf="is_loading == true; else DefaultButtonState">
                        <i class="fa-solid fa-gear fa-spin"></i> Updating Password....
                    </ng-container>
                    <ng-template #DefaultButtonState> Update Password </ng-template>

                </button>
            </div>
            <hr>
            <p> Your new password must be a minimum of 12 characters long, must not include your account name or any segments of your full name longer than two consecutive characters, and must incorporate at least three of the following criteria: </p>
            <ul>
                <li>English uppercase characters (A through Z)</li>
                <li>English lowercase characters (a through z)</li>
                <li>Base 10 digits (0 through 9)</li>
                <li>Non-alphabetic characters (for example, !, $, #, %)</li>
            </ul>
            <p class="mb-0"> Your password can not be the same as your previous 23 passwords. </p>
        </div>
    </div>
</section>