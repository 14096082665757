<section class="container" id="sdc-about-page">
    <h3>About</h3>

    <p>The Secure Data Commons (SDC) is a cloud-based analytics platform that enables access to traffic engineers, researchers, data scientists to various transportation related datasets. The SDC platform is a prototype created as part of the U.S.Department of Transportation (USDOT) research project. The objective of this prototype is to provide a secure platform, which will enable USDOT and the broader transportation sector to share and collaborate their research, tools, algorithms, analysis etc around sensitive datasets using modern, commercially available tools without the need to install or purchase tools/softwares.</p>
    <hr>

    <p>The primary objectives of Secure Data Commons platform are: </p>
    <ul>
        <li>Enable scalable data storage, data analysis and user access protocol via cloud based platforms.</li>
        <li>Leverage cloud capabilities to share complex (high volume, velocity and/or variety) transportation datasets with the transportation research community.</li>
        <li>Authorized access to users through a data use agreement with revocable access terms to protect the sensitivity of data.</li>
        <li>Provide users with predefined data analysis tools and encourage custom toolsets and open sharing of code and added datasets amongst the user community.</li>
        <li>Ensure sensitive data is protected through implementation of USDOT Information Technology (IT) security standards.</li>
    </ul>
    <hr>

    <p>The core capabilities of Secure Data Commons platform are:</p>
    <ul>
        <li>Granular access controls for data and code to users.</li>
        <li>Robust security architecture that secures datasets and user workstations.</li>
        <li>Ability for users to upload and share their own data.</li>
        <li>Ability to create workspaces and the ability to code and use analytic tools in the sandbox.</li>
        <li>Ability to create highly searchable and linkable base datasets.</li>
    </ul>
</section>