<footer>
    <h5 class="text-center mb-4"><a href="/">Secure Data Commons Platform</a></h5>

    <hr class="mb-4 text-white">

    <div class="row justify-content-center align-items-center mb-4">
        <ng-container *ngFor="let link of footer_links">
            <div class="col-auto text-center mb-2"> <a [href]="link.url">{{ link.title }}</a> </div>
        </ng-container>
    </div>

    <div class="d-flex justify-content-center align-items-center text-white gap-2">
        <p class="m-0"><a class="text-decoration-none text-white" href="https://www.fhwa.dot.gov/"> <i class="fa-solid fa-link me-2"></i>{{ organization }} </a></p>
        <p class="m-0"> <i class="fa-solid fa-map-pin me-1"></i> {{ address }}, {{ location }} </p>
        <p class="m-0"> <a href="tel:{{phone}}"><i class="fa-solid fa-phone me-1"></i> {{ phone }}</a> </p>
    </div>

</footer>