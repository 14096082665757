<section class="container" id="sdc-home-page">
    <div class="row">
        <div class="col-12 mb-4">
            <div class="card card-body p-0">
                <picture>
                    <source srcset="/assets/images/sdc-home-banner.webp" type="image/webp">
                    <source srcset="/assets/images/sdc-home-banner.jpg" type="image/jpeg">
                    <img src="/assets/images/sdc-home-banner.jpg" alt="">
                </picture>
            </div>
        </div>

        <div class="col-4">
            <div class="card h-100 card-body">
                <h4 class="mb-2">About SDC</h4>
                <p class="mb-auto"> The Secure Data Commons is an online analytic portal for transportation researchers. On this portal, researchers can take advantage of pre-established programming environments to access and analyze a growing set of transportation-related data sets. </p>
                <a class="btn mt-2 btn-primary" href="/about">Learn More</a>
            </div>
        </div>
        <div class="col-4">
            <div class="card h-100 card-body">
                <h4 class="mb-2">Datasets</h4>
                <p class="mb-auto"> Check out the list of current datasets that are available on SDC platform and learn how to get access to those datasets. </p>
                <a class="btn mt-2 btn-primary" href="/datasets">Learn More</a>
            </div>
        </div>
        <div class="col-4">
            <div class="card h-100 card-body">
                <h4 class="mb-2">Sign up</h4>
                <p class="mb-auto">Complete our online access request form and when you are approved you can begin creating your very own data analysis environments!</p>
                <a class="btn mt-2 btn-primary" href="/login">Learn More</a>
            </div>
        </div>
    </div>
</section>