<section class="container" id="sdc-about-datasets-page">
    <h3>Datasets</h3>

    <p>The Secure Data Commons (SDC) platform provides a data lake of various transportation related datasets. The datasets consists of structured, semi-structured or unstructured data and are stored in raw, curated and published formats. Each dataset has different data agreements based on the complexity and sensitivity of the data and is available for SDC users on a need-and-case basis upon appropriate approvals.</p>
    <hr>

    <h4>Dataset Formats</h4>
    <p>The dataset formats are described as follows:</p>
    <ul>
        <li>
            <h6>Raw datasets</h6>
            <p>The data stored in its native or original format are referred to as Raw datasets. These datasets could be in structured (databases, logs, financial data etc), semi-structured (HTML, XML, RDF, CSV) and un-structured (images, PDF, docs). This data is unaltered and stored in as-is format. The data can be received through continuous streaming sources (APIs, sensors) or one-time load from external sources.</p>
        </li>
        <li>
            <h6>Curated datasets</h6>
            <p>Data curation is a process of integrating the raw data collected from various sources, annotating the data and presenting it in such a way that the value of the data is maintained overtime and the data remains available for reuse and preservation. Curated datasets enable data discovery and retrieval, maintain quality, add value and provide for re-use over time, for researchers and data scientists. The curation process includes data transformations from unstructured and semi structured formats to a structured format, deduplication of data, data obfuscation, data cleansing etc thus providing high quality of data for researchers to create meaningful insights.</p>
        </li>
        <li>
            <h6>Published datasets</h6>
            <p>Published datasets are created by researchers to disclose their research for other users to verify and reuse them beyond the original purpose for which they were collected. Published datasets are a result of combining analysis on curated datasets available in SDC platform along with other datasets or algorithms that is owned/created by a researcher or data scientist.</p>
        </li>
    </ul>

    <hr>

    <p>Currently available list of datasets in Secure Data Commons Platform are:</p>

    <div class="provider">
        <h5>Waze - Connected Citizens Program (CCP)</h5>
        <div class="row">
            <div class="col-2">
                <picture> <img class="img-thumbnail" src="/assets/images/providers/provider-waze.png" alt=""></picture>
            </div>
            <div class="col-10">
                <p>The Connected Citizens Program is an ongoing partnership between Waze and various international government agencies to share publicly-available incident and road closure data. The Waze Connected Citizens Program (CCP) is a free, two-way data exchange empowering municipal decisions to achieve concrete community impact.</p>
                <p><strong>Waze provides</strong> real-time, anonymous, proprietary incident and slow-down information directly from the source: drivers themselves.</p>
            </div>
        </div>
    </div>
    <div class="provider">
        <h5>HSIS - FHWA Highway Safety Information System </h5>
        <div class="row">
            <div class="col-2">
                <div class="img-thumbnail">
                    <picture>
                        <img src="/assets/images/providers/provider-hsis.jpg" alt="">
                    </picture>
                </div>
            </div>
            <div class="col-10">
                <p>The Federal Highway Administration (FHWA) developed the Highway Safety Information System (HSIS) to support safety research programs and provides input for program policy decisions. HSIS is a roadway-based system that provides quality data on a large number of crash, roadway, and traffic variables. The crash, roadway inventory, and traffic volume data are acquired annually from a select group of States.</p>
                <p><strong>FHWA provides</strong> this data to researchers upon request through the HSIS webpage. Educators who wish to use HSIS data for instructional purposes in a road safety course should contact HSIS staff directly at <a href="mailto:Ana.Eigen@dot.gov">Ana.Eigen&commat;dot.gov</a>. </p>
                <p>For more information visit <a href="https://highways.dot.gov/research/safety/hsis">https://highways.dot.gov/research/safety/hsis</a>.</p>
                <a href="https://securedatacommons.atlassian.net/servicedesk/customer/portal/6" class="btn btn-sm btn-outline-primary">Data Request Form </a>
            </div>
        </div>
    </div>
</section>