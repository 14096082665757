<div class="container">
    <div class="card bg-light-subtle">
        <div class="card-body text-center">
            <h1>Oops!</h1>
            <h3>Something went wrong.</h3>
            <hr>
            <h6>page <code>{{ domain + path }}</code> does not exist.</h6>
            <p> If you dont know how you got here or feel this page is an error please contact <a href="mailto:sdc-support@dot.gov">SDC Support</a></p>
            <p>or</p>
            <p>Click here to navigate back to the <a class="btn btn-primary" href="/">SDC Home Page</a></p>
        </div>
    </div>
</div>