{{ "" // ::: ========== MODAL COMPONENT ========== ::: }}
{{ "" // IF YOU ARE NOT FAMILIAR WITH THIS COMPONENT, PLEASE REFRAIN FROM MAKING ANY MODIFICATIONS TO IT. }}
{{ "" // YOU CAN FIND USAGE NOTES ON THE MODAL IN THE `modal.component.ts` FILE. }}
{{ "" // ::: ========== MODAL COMPONENT ========== ::: }} 
<div class="modal" tabindex="-1" #ModalWrapper>
    <div class="modal-content" tabindex="1" [ngClass]="size">
        <div class="modal-header">
            <ng-content select="[modal-header]"></ng-content>
        </div>
        <div class="modal-body">
            <ng-content select="[modal-body]"></ng-content>
        </div>
        <div class="modal-footer">
            <ng-content select="[modal-footer]"></ng-content>
        </div>
    </div>
</div>
{{ "" // ::: ========== MODAL COMPONENT ========== ::: }}
{{ "" // ::: ========== MODAL COMPONENT ========== ::: }}