export const environment = {
  production: false,
  stage: 'dev',
  build: '0.0.1',
  resource_urls: {
    portal: 'portal.sdc-dev.dot.gov',
    portal_api: 'portal-api.sdc-dev.dot.gov/v1',
    guacamole: 'guacamole.sdc-dev.dot.gov',
    sftp: 'sftp.sdc-dev.dot.gov',
    sub1: 'sub1.sdc-dev.dot.gov',
    sub2: 'sub2.sdc-dev.dot.gov/v1',
  },

  // TODO -> Generated by Terraform, but values are hard coded to dev environment
  cognito: {
    Auth: {
      Cognito: {
        userPoolId: "us-east-1_sNIwupW53",
        userPoolClientId: "6s90hhstst6td8sdo1ntl3laet",
        loginWith: {
          oauth: {
            domain: "ecs-dev-sdc-dot-webportal.auth.us-east-1.amazoncognito.com",
            scopes: ["email", "profile", "openid"],
            redirectSignIn: [window.location.origin + "/login/redirect"],
            redirectSignOut: [window.location.origin + '/index.html'],
            responseType: "token",
          }
        }
      }
    }
  }
};
